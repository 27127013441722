import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import Modal from 'components/Modal';

import IconClose from 'images/icons/close.svg';

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div``;

const CloseBtn = styled.div`
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  height: 500px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ModalUsage = (props) => {
  const { isOpen, onCancel, content = '' } = props;

  return (
    <Modal
      isOpen={isOpen}
      title={
        <TitleWrapper>
          <Title></Title>
          <CloseBtn onClick={onCancel}>
            <img src={IconClose} />
          </CloseBtn>
        </TitleWrapper>
      }
      showCancelBtn={false}
      showOkBtn={false}
    >
      <Content>{parse(content)}</Content>
    </Modal>
  );
};

export default ModalUsage;
